.resourceRequest {
    margin: 0 auto;
    display: block;
    align-items: center;
    text-align: center;
    width: 100%;
}
.resourceRequestForm {
    text-align: left;
    padding: 1em;
}

.card-no-hover:hover {
   background-color: rgb(250, 249, 248) !important;
}

.dropdown-item {
    cursor: pointer;
    transition: all .1s ease;
}

.dropdown-item:hover {
    background-color:rgba(162, 162, 162, 0.583) !important;
}

.resource-request-grid-item {
    transition: .3s all ease;
}